<template>
  <v-container
    id="recover"
    class="fill-height justify-center mt-16"
    tag="section"
  >
    <div class="d-flex flex-wrap justify-center mb-2" style="width: 100%">
      <img src="@/assets/logo/Logo-Verde.png" height="50px" />
    </div>

    <div
      class="d-flex flex-wrap justify-center mb-12"
      style="width: 100%; font-size: 20px; font-weight: 300;"
    >
      <h4 style="font-weight: 400;">
        {{ $t("Genetica") }} · {{ $t("FarmacoGenetica") }}
      </h4>
    </div>

    <!-- <detalle-animado color="5DFFBD"></detalle-animado> -->

    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <div class="text-center">
            <h1 class="display-2 font-weight-bold mb-8" style="color: #1C093A;">
              {{ $t("recuperar_contrasena") }}
            </h1>
          </div>
          <!-- <template v-slot:heading>
          </template> -->
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(recover)">
              <v-card-text class="text-center pa-0">
                <p>
                  {{$t('recovery_claim_1')}}
                </p>
                <p>
                  {{$t('recovery_claim_2')}}
                </p>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="email"
                >
                  <v-text-field
                    autocomplete="off"
                    rounded
                    outlined
                    v-model="email"
                    :label="$t('Direccion email') + '*'"
                    :error-messages="errors"
                    color="secondary"
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions class="pa-0 justify-center">
                <v-btn
                  default
                  rounded
                  color="primary"
                  type="submit"
                  class="font-weight-bold accent--text mr-0 ml-2"
                >
                  {{ $t("recuperar") }}
                </v-btn>
              </v-card-actions>
              <br />
              <v-card-actions class="pa-0 justify-center">
                <a class="ml-1" @click="$router.push({ name: 'Login' })">
                  {{ $t("volver") }}</a
                >
              </v-card-actions>
            </form>
          </validation-observer>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {},
  name: "PagesLogin",
  $_veeValidate: {
    validator: "new"
  },

  data: () => ({
    email: ""
  }),
  methods: {
    ...mapActions("auth", ["requestPasswordRecover"]),
    recover() {
      let self = this;
      self.$nprogress.start();
      this.requestPasswordRecover(this.email)
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch(error => {
          self.$dialog.error({
            text: self.$t(error.message),
            title: self.$t(`Algo salio mal al iniciar la sesion`),
            icon: false,
            actions: [self.$t("Cerrar")]
          });
        })
        .then(() => {
          self.$nprogress.done();
        });
    }
  }
};
</script>
